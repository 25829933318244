@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arimo:ital,wght@0,400..700;1,400..700&display=swap');

body {
    font-family: 'Open Sans', serif;
}

.font-rubik {
    font-family: "Rubik", sans-serif;
}
.font-arimo {
    font-family: "Arimo", sans-serif;
}

.inst-gradient {
    background: linear-gradient(36deg, rgba(254,218,117,1) 0%, rgba(250,126,30,1) 30%, rgba(214,41,118,1) 50%, rgba(150,47,191,1) 70%, rgba(150,47,191,1) 87%);
}

a {
    transition: all 150ms ease-in-out;
}


nav button:hover, nav a:hover, #projects a:hover, #socialLinks a:hover, footer a:hover{
    opacity: 70%;
}